/* eslint-disable */
import React from 'react';
import { toast } from 'react-toastify';
import '../../css/ReportModal.css';
import ReportApi from '../../api/report/Report';
import { Glyphicon, Image, Badge, Button, Modal, OverlayTrigger, Popover, DropdownButton, MenuItem } from 'react-bootstrap';
import FullScreenLoader from '../ui-components/FullScreenLoader';
import DropdownToggle from 'react-bootstrap/lib/DropdownToggle';
import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';
import VerificationAPI from 'src/api/verification/Verification';
import { VerificationTypeId } from 'src/pages/ExternalVerification';
import { Rnd } from 'react-rnd';

const POSSIBLE_TYPES = [
    { id: 6, text: "This person is unknown" },
    { id: 1, text: "Image unsuitable" },
    { id: 2, text: "Headline unsuitable" },
    { id: 3, text: "Qualifications unsuitable" },
    { id: 4, text: "Skills unsuitable" },
    { id: 5, text: "Name unsuitable" },
    { id: 8, text: "Our records don't match the details provided" },
    { id: 9, text: "Other" }
]

export class ReportModal extends React.Component<{
    options?: {
        type: number;
        id: string;
        mode: "admin" | "contact",
        recipientContactName?: string;
        message?: string;
    },
    onClose: () => void;
}, {
    additionalDetails?: string;
    typeId?: number;
    loading: boolean;
    manuallyEdited?: boolean;
    moreTextboxShowing?: boolean;
}> {
    state = {
        additionalDetails: this.props.options?.message ? this.props.options.message : "",
        typeId: this.props.options?.message ? 9 : 0,
        loading: false,
        manuallyEdited: this.props.options?.message ? true : false,
        moreTextboxShowing: true,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    async report() {
        let res: any = null;

        if (!this.props.options) {
            return;
        }

        if (!this.state.typeId) {
            toast.error("Please select a reason");
            return;
        }

        this.setState({
            loading: true
        })

        if (this.props.options.mode === "admin") {
            res = await ReportApi.submit(
                this.props.options?.type || 0,
                this.state.typeId || 0,
                this.state.additionalDetails || "",
                this.props.options?.id || ""
            ).catch(() => {
                toast.error("Unable to submit report");
                this.setState({
                    loading: false
                })
            })
        } else {
            res = await VerificationAPI.createVerification(VerificationTypeId.Rejected, new Date().toISOString(), this.props.options.id, this.state.additionalDetails || "").catch(() => {
                toast.error("Unable to submit report");
                this.setState({
                    loading: false
                })
            })
        }

        if (res) {
            toast.success("Submitted");
            this.props.onClose();
            if (this.props.options.mode === "contact") {
                setTimeout(() => {
                    window.location.reload();
                }, 1200);
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    show={this.props.options !== undefined}
                    onHide={() => { 
                        this.props.onClose();
                    }}
                    className="report-modal base-modal"
                >
                    <Rnd
                    >
                    <Modal.Body style={{
                        background: "white",
                        borderRadius: 12
                    }}>
                        <h2>Please describe the issue.</h2>
                        <p>{this.props.options ? (this.props.options.mode === "admin" ? "We will take a look and remove anything that is unsuitable" : "We will let this contact know about the issue, and they can ask for verificaction again once they have solved the issue") : ""}</p>
                        <DropdownButton
                            id="report-type-options"
                            title={this.state.typeId ? POSSIBLE_TYPES.find(pt => this.state.typeId && pt.id === this.state.typeId)?.text : "Please Select"}
                            className='unsuitable'
                            value={this.state.typeId}
                        >
                            {POSSIBLE_TYPES.map(
                                (opt) => (
                                    <MenuItem
                                        onSelect={() => {
                                            this.setState({
                                                typeId: opt.id
                                            })
                                            if (!this.state.manuallyEdited) {
                                                this.setState({
                                                    additionalDetails: opt.text !== "Other" ? opt.text : "",
                                                    moreTextboxShowing: false
                                                }, () => {
                                                    this.setState({
                                                        moreTextboxShowing: true 
                                                    })
                                                })
                                            }
                                        }}
                                        key={opt + ""}
                                        selected={this.state.typeId === opt.id}
                                    >
                                        { opt.text }
                                    </MenuItem>
                                )
                            )}
                        </DropdownButton>
                        { (this.state.moreTextboxShowing) &&
                            <div className='more-comments-textbox'>
                                <textarea
                                    rows={4}
                                    placeholder='Add more comments'
                                    value={this.state.additionalDetails || ""}
                                    onChange={e => {
                                        this.setState({ 
                                            additionalDetails: e.target.value,
                                            manuallyEdited: e.target.value !== "" && e.target.value !== this.state.additionalDetails
                                        });
                                    }}
                                />
                            </div>
                        }
                        <div className='options'>
                            <button 
                                onClick={() => {
                                    this.props.onClose();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className='submit'
                                onClick={() => {
                                    this.report();
                                }}
                            >
                                {this.props.options?.mode === "admin" ? "Submit Report" : "Send Notes to " + (this.props.options?.recipientContactName || "Contact")}
                            </button>
                        </div>
                    </Modal.Body>
                    </Rnd>
                    <FullScreenLoader loadingMessage={this.state.loading ? " " : ""} />
                </Modal>
            </React.Fragment>
        )
    }
}

export default ReportModal;