/* eslint-disable */
import { toast } from 'react-toastify';
import { UserType } from '../../state-containers/user-type.container';

import AppConfig from '../../components/config/Config';
import afetch from './../AuthenticatedFetch';
import fetch from './../UnauthenticatedFetch';
import { UserVerificationResult, UserVerificationPendingResult, VerificationDetailsDto, VerificationDto, WorkerVerificationResult } from './ResponseTypes';
import { VerificationTypeId } from 'src/pages/ExternalVerification';
import { VerificationDetailDto } from '../availability/ResponseTypes';

const VerificationAPI = {
    getAll: () => {
        const url = `${AppConfig.Settings.api.externalUri}/verification/all`;

        return afetch.request<null, { data: WorkerVerificationResult[] }>(
            url,
            'GET',
            null,
            "Unable to fetch verifications"
        );
    },
    unverified: () => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/verification/unverified`;

        return afetch.request<null, UserVerificationResult[]>(
            url,
            'GET',
            null,
            'Unable to fetch industries'
        );
    },
    updateVerificationStatus: (id: string, verified: boolean) => {
        const url = `${AppConfig.Settings.api.externalUri}/admin/userprofile/reviews/${id}/response?accept=${verified}`;

        return afetch.request<null, UserVerificationResult[]>(
            url,
            'GET',
            null,
            'Unable to update verification status'
        );
    },
    unverifiedPending: (page: number, userTypeId: UserType) => {
        const url = `${AppConfig.Settings.api.externalUri}/admin/userprofile/reviews/outstanding?&rowsPerPage=100&page=${(page+1)}${ userTypeId ? ("&usertypeid=" + userTypeId) : "" }`;

        return afetch.request<null, UserVerificationPendingResult>(
            url,
            'GET',
            null,
            'Unable to get pending users'
        );
    },
    verifyUser: (userId: string) => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/verification/verify/${userId}`;

        return afetch.request<null>(url, 'GET', null, 'Unable to verify user');
    },
    verifyOrganisation: (orgId: string) => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/verification/verifyOrganisation/${orgId}`;

        return afetch.request<null>(
            url,
            'GET',
            null,
            'Unable to verify organization'
        );
    },
    unverifyOrganisation: (orgId: string) => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/verification/unverifyOrganisation/${orgId}`;

        return afetch.request<null>(
            url,
            'GET',
            null,
            'Unable to unverify organization'
        );
    },
    verifyUserPhoto: async (image: File) => {
        const url = `${AppConfig.Settings.api.baseUri}/api/profileimage/verify`;
        const toastId = toast.info('Verifying profile image...', {
            autoClose: false
        });

        const success = await afetch.uploadFile(
            url,
            image,
            'Failed to verify profile image'
        );

        toast.dismiss(toastId);

        return success;
    },
    verifyCompanyPhoto: async (image: File) => {
        const url = `${AppConfig.Settings.api.baseUri}/publicapi/company/image/verify`;
        const toastId = toast.info('Verifying organization image...', {
            autoClose: false
        });

        const success = await afetch.uploadFile(
            url,
            image,
            'Failed to verify organization image'
        );

        toast.dismiss(toastId);

        return success;
    },
    getFlags: async (token: string) => {
        const url = `${AppConfig.Settings.api.externalUri}/verification/external?token=${token}`;

        return fetch.request<null, VerificationDetailsDto>(
            url,
            'GET',
            null,
            'Unable to get verification statuses'
        );
    },
    submitVerification: async (token: string, flags: VerificationDto[], rejectedReason?: string) => {
        const url = `${AppConfig.Settings.api.externalUri}/verification/external?token=${token}${rejectedReason ? ("&rejectedReason=" + rejectedReason) : ""}`;

        return fetch.request<VerificationDto[], null>(
            url,
            'PUT',
            flags,
            'Unable to submit verification'
        );
    },
    validateInformation(token: string, type: "phone" | "email", value: string) {
        const url = `${AppConfig.Settings.api.externalUri}/verification/external/validate?token=${token}&type=${type}&value=${value}`;

        return fetch.request<null, boolean>(
            url,
            'GET',
            null,
            'Unable to validate information'
        );
    },
    validateInformationAuth(userId: string, type: "phone" | "email", value: string) {
        const url = `${AppConfig.Settings.api.externalUri}/verification/validate?userId=${userId}&type=${type}&value=${value}`;

        return afetch.request<null, boolean>(
            url,
            'GET',
            null,
            'Unable to validate information'
        );
    },
    createVerification(typeId: VerificationTypeId, start: string, verifiedContactId: string, rejectionReason?: string) {
        return afetch.request<{
            typeId: VerificationTypeId,
            start: string,
            verifiedContactId: string,
            rejectionReason?: string
        }, VerificationDetailDto>(
            `${AppConfig.Settings.api.externalUri}/verification`,
            'POST',
            {
                typeId,
                start,
                verifiedContactId,
                rejectionReason
            },
            'Unable to validate information'
        );
    },
    endVerification(id: number, end: string) {
        return afetch.request<{
            id: number,
            end: string
        }, VerificationDetailDto>(
            `${AppConfig.Settings.api.externalUri}/verification`,
            'DELETE',
            {
                id,
                end
            },
            'Unable to validate information'
        );
    },
    swapContacts(sourceUserId: string, targetUserId: string) {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/verification/swap?sourceUserId=${sourceUserId}&targetUserId=${targetUserId}`,
            'POST',
            null,
            'Unable to swap contacts'
        );
    },
    remindRejection(id: number) {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/verification/remind-rejection/${id}`,
            'POST',
            null,
            'Unable to remind the contact'
        );
    },
    removeRejection(id: number) {
        return afetch.request<null, null>(
            `${AppConfig.Settings.api.externalUri}/verification/remove-rejection/${id}`,
            'POST',
            null,
            'Unable to remove the contact'
        );
    }
};

export default VerificationAPI;
