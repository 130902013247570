const theme = {
    colours: {
        red: '#DD2E1B',
        green: '#4A8132',
        orange: '#ffb606',
        updatedge: '#17333F',
        updatedge2: '#3D72E7',
        rowSelected: '#CEF4C1',
        rowHover: 'rgba(10, 30, 65, .025)',
        red2: '#FB5B5A',
        amber: '#F9A721',
        green2: '#64A94E',
        blue: '#509FFE',
        blue2: '#3D72E7',
        blue3: '#3c53a4',
        darkBlue: '#34495e',
        grey: '#8e8e8e',
        yellow: '#FFD523',
        boxShadow: 'rgb(204, 204, 204) 0px 0px 4px 0px;',
        greenAG: '#5D8840'
    }
};

export default theme;
