/* eslint-disable */
import React from 'react';
import CompanyApi from '../../api/company/Company';
import theme from '../../css/theme';
import styled from 'styled-components';
import FullScreenLoader from './FullScreenLoader';
import { EphemeralAccessTokenStorageKey } from '../layout/EphemeralAccessLayout';
import InternalTracker from '../../InternalTracker';
import OrganisationApi from '../../api/organisation/Organisation';
import { WorkerMetadata } from 'src/pages/Schedule';
import ProfileApi from '../../api/profile/Profile';
import AppConfig from '../config/Config';

interface BackgroundProps {
    worker?: WorkerMetadata
}

export class TimeSheetHeader extends React.Component<BackgroundProps, {
    agencyId?: string,
    externalHirerId?: string,
    agencyName?: string,
    organisationName?: string;
    clearBitFailed?: boolean;
    organisationWebsite?: string;
    agencyWebsite?: string;
    otpLink?: string;
    workerRota?: boolean;
    profileExpanded?: boolean;
}> {

    constructor(props) {
        super(props)
        this.state = {
            agencyId: "",
            externalHirerId: "",
            agencyName: "",
            organisationName: "",
            clearBitFailed: false,
            organisationWebsite: "",
            agencyWebsite: "",
            otpLink: "",
            workerRota: window.location.pathname.includes("worker"),
            profileExpanded: false
        }
    }

    async componentDidMount() {
        document.body.style.backgroundColor = theme.colours.greenAG
        const loadingElement = document.querySelector('.full-screen-loader-wrapper') as HTMLElement;
        if (loadingElement) {
            loadingElement.style.backgroundColor = theme.colours.greenAG
        }
        document.body.style.overflow = 'auto'
        if (document.querySelector("#react-app>div")) {
            // @ts-ignore
            document.querySelector("#react-app>div")!.style.paddingTop = 60
        }

        if (!window.location.pathname.includes("worker") && window.location.pathname !== "/schedule") {
            let metadata = window.location.pathname.startsWith("/external/timesheet") ?
            await CompanyApi.getTimeSheetMetadata(localStorage.getItem(EphemeralAccessTokenStorageKey) || "").catch(e => {  }) :
            await CompanyApi.getRotaMetadata(localStorage.getItem(EphemeralAccessTokenStorageKey) || "").catch(e => {  });

            if (metadata) {
                const hirerDomain = metadata.organisationDomain ? metadata.organisationDomain : metadata.organisation.website;
                this.setState({
                    externalHirerId: metadata.externalHirerId,
                    agencyId: metadata.agencyId,
                    agencyName: metadata.agencyName,
                    organisationName: metadata.organisation.organisationName,
                    otpLink: metadata.otpLink,
                    clearBitFailed: !hirerDomain,
                    organisationWebsite: hirerDomain,
                    agencyWebsite: metadata.agencyId === "F10FD77E-8B9A-47E3-9472-6A7C40788178" ? "https://4myschools.org/" : (metadata.agencyWebsite || "https://www.updatedge.com/")
                })
                localStorage.setItem("timesheet-otp-link", metadata.otpLink || "");
                localStorage.setItem("timesheet-school", metadata.organisation.organisationName || "");
                localStorage.setItem("timesheet-school-id", metadata.externalHirerId || "");
                localStorage.setItem("timesheet-agency-id", metadata.agencyId || "");

                InternalTracker.init({
                    emailAddress: metadata.organisation.organisationName,
                    organisationName: metadata.organisation.organisationName,
                    organisationId: metadata.externalHirerId,
                })

                InternalTracker.trackEvent("", {
                    category: 'Schedule',
                    action: 'External Rota & Availability',
                    customDimensions: [{ id: "REPLACE", value: metadata.organisation.organisationName + " - " + metadata.organisation.website }]
                });

            } else {
                alert("This link has expired, try using the link from your latest email, alternatively you can sign up or log in.");
                window.location.href = "/"
            }
        }
    }

    render() {

        return (
            <React.Fragment>
                { (this.state.agencyName || this.props.worker) &&
                    <Sponsored>
                        { (!this.state.workerRota) ?
                            <div onClick={() => {
                                window.open(this.state.agencyWebsite)
                            }}>
                                <img
                                    src={CompanyApi.getOrganisationProfileImageUrl(this.state.agencyId || "")}
                                    alt={this.state.organisationName + " logo"}
                                />
                                <p>Access to your contacts availability, skills, qualifications & proximity is sponsored by {this.state.agencyName}</p>
                            </div>
                            :
                            <div onClick={() => {
                                window.open("https://my.updatedge.com");
                            }}>
                                <img
                                    src="https://test-images.updatedge.com/images/organisation/dba81a2c-2586-4d53-9541-1aeb1848afb6.png"
                                    alt={this.state.organisationName + " logo"}
                                />
                                {/* TODO Replace logo url */}
                                <p>Add further agencies and business hirers to share your availability with</p>
                            </div>
                        }
                    </Sponsored>
                }
                <Wrapper>
                    { (!this.state.agencyId && !this.props.worker) &&
                        <FullScreenLoader
                            fullBlock={true}
                            noLoadingMessage={true}
                        />
                    }
                    { (this.state.agencyId) ?  
                        <div className="profile">
                            { (!this.state.clearBitFailed && this.state.organisationWebsite) ?
                                <img 
                                    src={OrganisationApi.getExtOrgPicture(this.state.organisationWebsite)}
                                    onError={() => {
                                        this.setState({
                                            clearBitFailed: true
                                        })
                                    }}
                                />
                                :
                                <img
                                    src={CompanyApi.getOrganisationProfileImageUrl(this.state.agencyId || "")}
                                    alt={this.state.organisationName + " logo"}
                                />
                            }
                            <div>
                                <h2>{this.state.organisationName}</h2>
                                <h3>Sent by {this.state.agencyName}</h3>
                            </div>
                        </div>
                        :
                        <div 
                            className="profile"
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                this.setState({
                                    profileExpanded: !this.state.profileExpanded
                                })
                            }}
                        >
                            { (this.props.worker?.id || this.props.worker?.imageUrl) &&
                                <img src={this.props.worker.id ? ProfileApi.getProfileImageUrl(this.props.worker?.id) : this.props.worker.imageUrl} />
                            }
                            <div>
                                <h2>{this.props.worker?.firstName}{' '}{this.props.worker?.lastName}</h2>
                                { (this.props.worker?.headline) &&
                                    <h3 style={{
                                        maxHeight: this.state.profileExpanded ? undefined : 66,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{this.props.worker.headline}</h3>
                                }
                            </div>
                        </div>
                    }
                    <button
                        onClick={() => {
                            if (this.state.workerRota) {
                                window.open(AppConfig.Settings.pwaUri, "_blank");
                            } else {
                                window.open(this.state.otpLink || "/", "_blank");
                            }
                        }}
                    >
                        See More
                    </button>
                </Wrapper>
            </React.Fragment>
        );
    }
}

const Sponsored = styled.div`
    div {
        background-color: white;
        padding: 10px 10px;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 0;
        width: 460px;
        z-index: 5;
        border-radius: 0 0 16px 16px;
        box-shadow: ${theme.colours.boxShadow};
    
        p {
            font-weight: 700;
            margin: 0 0 0 12px;
            font-size: 1.1em;
            text-align: center;
        }
    
        img {
            width: 40px;
            height: 40px;
            border-radius: 52px;
        }
    }

    @media (max-width: 1000px) {

        div {
            position: static;
            transform: unset;
            width: 100%;
        }
    }
`

const Wrapper = styled.div`
    margin: 20px auto 20px auto;
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .profile {
        max-width: 280px;
        display: flex;
        align-items: center;

        img {
            flex-basis: 52px;
            height: 52px;
            width: 52px;
            border-radius: 100%;
        }

        & > div {
            padding-left: 10px;
            flex-basis: calc(100% - 52px);

            h2 {
                font-size: 1.1em;
                line-height: 1.5em;
                font-weight: 700;
                margin-bottom: 10px;
                color: white;
                margin: 0 0 4px 0;
            }

            h3 {
                font-size: 1em;
                color: white;
                margin: 0;
            }
        }
    }

    button {
        background-color: white;
        border: none;
        padding: 6px 12px;
        border-radius: 4px;
        font-weight: 700;
    }

    @media (max-width: 600px) {
        display: block;

        .profile {
            margin: 0 auto 20px auto;
            max-width: unset;
        }

        &>button {
            width: 100%;
            padding: 10px 12px;
            border-radius: 24px;
        }
    }
`;
